//Preparing for AF pages (smarty/templates/pfaf-*)

var wipwrp = window.wipwrp || {};

wipwrp.pfafMyPos = (function(doc,win) {

    let self = {};
    let contactForm;
    let scrollerBtn;

    self.init = function () {
        contactForm = doc.querySelector('[data-mypos-form]');
        scrollerBtn = doc.querySelector('[data-mypos-scrollto]');
        _listenScroller();
    }

    function _listenScroller(){
        if (!scrollerBtn || !contactForm)return;
        scrollerBtn.addEventListener('click',()=>{
            contactForm.scrollIntoView({ behavior: 'smooth', block: 'center'});
        })
    }


    return self;
})(document, window);
